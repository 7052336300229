










































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Model from '@/models/Model';
import { BModal, BvModalEvent } from 'bootstrap-vue';

@Component
export default class ModelCopyModal extends mixins(Toasts) {
  public static MODAL_ID = 'modal-copy-model';

  @Prop({
    required: true,
    default: () => null,
  })
  protected model!: Model | null;

  @Prop({
    required: true,
  })
  protected projectId!: string;

  protected newNameValidationErrorMessage = '';

  protected newName = '';
  protected newNameValidationState: boolean | null = null;

  protected linkToNewModel = true;
  protected openNewModel = true;

  @Watch('model')
  protected handleModelChange() {
    this.newName = (this.model?.name ?? '') + ' Copy';
  }

  @Watch('newNameValidationErrorMessage')
  protected handleNameValidationErrorMessageChange(newVal) {
    if (newVal !== null) {
      this.newNameValidationState = false;
    } else {
      this.newNameValidationState = null;
    }
  }

  get title(): string {
    return 'Copy Model: ' + (this.model?.name ?? '');
  }

  get id(): string {
    return ModelCopyModal.MODAL_ID;
  }

  public showNewNameValidationError(validationErrorMessage: string): void {
    if (validationErrorMessage && validationErrorMessage.length > 0) {
      this.newNameValidationState = false;
    } else {
      this.newNameValidationState = null;
    }

    this.newNameValidationErrorMessage = validationErrorMessage;
  }

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected handleCancel(e: BvModalEvent): void {
    this.$emit('cancel', e);
  }

  protected createCopy(): void {
    if (this.newName.trim().length === 0) {
      this.newNameValidationState = false;
      this.newNameValidationErrorMessage = 'Name is required';
    } else {
      this.$emit('create-copy', { name: this.newName, createLink: this.linkToNewModel, openModel: this.openNewModel });
      this.newNameValidationState = null;
    }
  }
}
