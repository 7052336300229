














































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { getCurrentUserID, hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import moment from 'moment';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import WysiwygEditor from '@/components/WysiwygEditor.vue';
import ReviewService from '@/services/ReviewService';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import Review from '@/models/reviews/Review';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';

@Component({
  components: { WysiwygEditor },
})
export default class AssignmentDetailsModal extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected readonly currentUserId = getCurrentUserID();

  protected shouldBeVisible(action: PossibleAction, project?: Project, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, project, assignment);
    return permission != null && permission;
  }

  @Prop({ required: false })
  protected project?: Project;
  @Prop({ required: true })
  protected assignment!: ReviewAssignment;
  @Prop({ required: true })
  protected parent!: Vue;
  @Prop({ required: false, default: false })
  protected showReviewButton!: boolean;

  protected lastAssignmentId = -1;
  protected currentReviewsOfUser: Review[] = [];
  protected inProgressReviewsOfUser: Review[] = [];
  protected readonly states = [AssignmentStateEnum.OK, AssignmentStateEnum.NOT_OK, AssignmentStateEnum.UNCLEAR];
  protected selectedAssignmentState: AssignmentStateEnum | null = null;
  protected stateSelectorState: boolean | null = null;
  protected currentReviews: Review[] | null = null;

  mounted(): void {
    this.loadCurrentReviewList();
  }

  protected deleteReviewAssignment(assignment: ReviewAssignment): void {
    if (hasPermission(PossibleAction.CAN_DELETE_ASSIGNMENT, this.project, assignment)) {
      this.$bvModal.hide('modal-show-assignment');
      this.$root.$emit('delete-assignment', { assignment, target: this.parent });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadCurrentReviewList(): void {
    if (
      this.assignment &&
      this.assignment.id &&
      hasPermission(PossibleAction.CAN_GET_REVIEW, undefined, this.assignment)
    ) {
      ReviewService.getAllByAssignmentId(this.assignment.id)
        .then((reviews) => {
          this.currentReviews = reviews;
        })
        .catch((backendError) => {
          if (backendError.response.status !== 403) {
            this.showToast('Failed to load', 'Could not load the reviews.', 'danger');
          }
        });
    }
  }

  protected showOverrideStateModel(): void {
    this.$bvModal.hide('modal-show-assignment');
    this.stateSelectorState = null;
    this.selectedAssignmentState = null;
    this.$bvModal.show('override-modal');
  }

  protected overrideState(event: Event, assignment: ReviewAssignment): void {
    if (this.selectedAssignmentState === null) {
      this.stateSelectorState = false;
      event.preventDefault();
      return;
    }
    if (hasPermission(PossibleAction.CAN_UPDATE_ASSIGNMENT, this.project, assignment)) {
      this.$root.$emit('update-assignment', { assignment, state: this.selectedAssignmentState, target: this.parent });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
    this.$bvModal.hide('modal-show-assignment');
  }

  protected startReviewProcess(assignment: ReviewAssignment): void {
    if (hasPermission(PossibleAction.CAN_SAVE_REVIEW, this.project, assignment)) {
      this.$bvModal.hide('modal-show-assignment');
      this.$root.$emit('start-review-process', { assignment, target: this.parent });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected loadReviewListOfAssignment(): boolean {
    if (this.assignment && this.assignment.id && this.lastAssignmentId !== this.assignment.id) {
      this.lastAssignmentId = this.assignment.id;
      ReviewService.getAllByAssignmentId(this.assignment.id)
        .then((reviews) => {
          this.currentReviewsOfUser = reviews.filter((r) => r.userId === this.currentUserId);
          this.inProgressReviewsOfUser = reviews.filter(
            (r) => r.userId === this.currentUserId && r.state.toString() == 'IN_REVIEW'
          );
        })
        .catch((backendError) => {
          if (backendError.response.status !== 403) {
            this.showToast('Failed to load', 'Could not load the reviews.', 'danger');
          }
        });
    }
    return true;
  }

  protected resumeReviewProcess(review: Review, assignment: ReviewAssignment): void {
    if (hasPermission(PossibleAction.CAN_SAVE_REVIEW, this.project, assignment)) {
      this.$bvModal.hide('modal-show-assignment');
      this.$root.$emit('resume-review-process', { assignment: assignment, review: review, target: this.parent });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }
}
