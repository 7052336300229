import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export class ModalStatic extends Vue {
  @Prop({
    required: false,
    default: () => false,
  })
  protected static!: boolean;
}
