import Model from '@/models/Model';
import ModelHistoryInput from '@/models/ModelHistoryInput';
import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class ModelUpdate {
  @JsonProperty('modelDTO')
  private _model: Model;
  @JsonProperty('modelHistoryInputDTO')
  private readonly _modelHistoryInput: ModelHistoryInput;

  constructor(model: Model, modelHistoryInput: ModelHistoryInput) {
    this._model = model;
    this._modelHistoryInput = modelHistoryInput;
  }

  get model(): Model {
    return this._model;
  }

  set model(value: Model) {
    this._model = value;
  }

  get modelHistoryInput(): ModelHistoryInput {
    return this._modelHistoryInput;
  }
}
