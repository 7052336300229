import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { AddModelToCompareListDTO } from '@/model-difference/store-module/ModelDifference';
import { ModelDetailsDTO } from '@/model-difference/interfaces/ModelDetailsDTO';

@Component({})
export class ModelDifferenceCompareListMixin extends Vue {
  @Getter('compareList', {
    namespace: 'ModelDifference',
  })
  protected modelDifferenceCompareList!: Record<string, ModelDetailsDTO>;

  @Getter('capacity', {
    namespace: 'ModelDifference',
  })
  protected modelDifferenceCompareListCapacity!: number;

  @Getter('capacityLeft', {
    namespace: 'ModelDifference',
  })
  protected modelCompareListHasCapacityLeft!: number;

  @Mutation('addModelToCompareList', {
    namespace: 'ModelDifference',
  })
  protected addModelToCompareList!: (modelDetails: AddModelToCompareListDTO) => void;

  @Mutation('setCompareList', {
    namespace: 'ModelDifference',
  })
  protected setCompareList!: (compareList: Record<string, string>) => void;

  @Mutation('removeModelFromCompareList', {
    namespace: 'ModelDifference',
  })
  protected removeModelFromCompareList!: (modelId: string) => void;

  protected isModelInCompareList(modelId: string): boolean {
    return Object.keys(this.modelDifferenceCompareList).indexOf(modelId) > -1;
  }

  get modelDifferenceCompareListSize(): number {
    return Object.keys(this.modelDifferenceCompareList).length;
  }

  get modelDifferenceAllowedModelConfigId(): string | null {
    if (this.modelDifferenceCompareListSize > 0) {
      return this.modelDifferenceCompareList[Object.keys(this.modelDifferenceCompareList)[0]].configId;
    }
    return null;
  }
}
