








































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { hasPermission } from '@/auth/AuthService';
import ModelService from '@/services/ModelService';
import Model from '@/models/Model';
import { PossibleAction } from '@/auth/PossibleAction';
import Project from '@/models/Project';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ModelExportModal from '@/components/modals/ModelExportModal.vue';
import ModelAnalysisModal from '@/components/modals/ModelAnalysisModal.vue';
import moment from 'moment';
import ModelUpdate from '@/models/ModelUpdate';
import ModelHistoryInput from '@/models/ModelHistoryInput';
import { ModelHistoryCategoryEnum } from '@/enums/ModelHistoryCategoryEnum';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import ModelLinksModal from '@/components/modals/ModelLinksModal.vue';
import ModelCopyModal from '@/components/modals/ModelCopyModal.vue';
import { CopyModelMixin } from '@/mixins/CopyModelMixin';
import RunPipelinesModal from '@/components/modals/RunPipelinesModal.vue';

@Component({
  components: { ModelCopyModal, ModelLinksModal, ModelExportModal, ModelAnalysisModal, RunPipelinesModal },
})
export default class ModelDetailsModal extends mixins(Toasts, CopyModelMixin) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, project?: Project, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, project, assignment);
    return permission != null && permission;
  }

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @Prop({ required: true })
  protected currentProject!: Project;

  @Prop({ required: true })
  protected selectedModel!: Model;

  protected selectedModelEditActive = false;
  protected selectedModelChangedName = '';
  protected selectedModelValidatorState: boolean | null = null;
  protected selectedModelErrorMessage = 'Required (max. Length 255)';
  protected selectedModelInProgress = false;

  protected updateModel(): void {
    if (hasPermission(PossibleAction.CAN_UPDATE_MODEL, this.currentProject)) {
      if (this.selectedModelChangedName && this.selectedModelChangedName.length < 256) {
        if (this.selectedModelChangedName !== this.selectedModel.name) {
          this.selectedModelInProgress = true;
          const oldName = this.selectedModel.name;
          this.selectedModel.name = this.selectedModelChangedName;
          ModelService.update(
            new ModelUpdate(
              this.selectedModel,
              new ModelHistoryInput(
                'Changed name from ' + oldName + ' to ' + this.selectedModel.name,
                ModelHistoryCategoryEnum.OTHER
              )
            )
          )
            .then((updatedModel) => {
              this.showToast(
                'Successfully updated',
                "Model '" + updatedModel.name + "' successfully updated",
                'success'
              );
              this.$emit('update:selected-model', updatedModel);
              this.selectedModelEditActive = false;
              this.selectedModelValidatorState = null;
            })
            .catch((backendError) => {
              this.selectedModel.name = oldName;
              let msg,
                title = 'Failed to update';
              if (backendError.response.status === 409) {
                this.selectedModelValidatorState = false;
                this.selectedModelErrorMessage = 'Name is already in use or model is under review.';
                return;
              } else if (backendError.response.status === 404) {
                msg = 'Could not find the model.';
              } else if (backendError.response.status === 400) {
                msg = 'Could not find the model config.';
              } else if (backendError.response.status === 403) {
                title = 'Action denied';
                msg = 'You do not have the required rights.';
              } else {
                msg = 'Could not update model: ' + backendError.response.status;
              }
              this.showToast(title, msg, 'danger');
            })
            .finally(() => (this.selectedModelInProgress = false));
        } else {
          this.selectedModelEditActive = false;
          this.selectedModelValidatorState = null;
        }
      } else {
        this.selectedModelValidatorState = false;
        this.selectedModelErrorMessage = 'Required (max. Length 255)';
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected deleteModel(model: Model): void {
    if (hasPermission(PossibleAction.CAN_DELETE_MODEL, this.currentProject)) {
      this.$bvModal.hide('modal-show-model');
      this.$bvModal
        .msgBoxConfirm('Do you want to delete this model?', {
          title: 'Delete Model: ' + model.name,
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          hideHeaderClose: true,
          centered: true,
        })
        .then((confirmationResponse) => {
          if (confirmationResponse && model.id) {
            this.setLoading(true);
            ModelService.delete(model.id)
              .then(() => {
                this.showToast('Successfully deleted', "Model '" + model.name + "' successfully deleted.", 'success');
                this.$emit('delete-model', { model });
              })
              .catch((backendError) => {
                if (backendError.response.status === 403) {
                  this.showToast('Action denied', 'You do not have the required rights.', 'danger');
                } else if (backendError.response.status === 409) {
                  this.showToast('Failed to delete', 'Model is currently under review.', 'danger');
                } else {
                  this.showToast(
                    'Failed to delete',
                    'Could not delete model: ' + backendError.response.status,
                    'danger'
                  );
                }
              })
              .finally(() => this.setLoading(false));
          }
        })
        .catch(() => {
          this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected showCopyModal(): void {
    this.$bvModal.hide('modal-show-model');
    Vue.nextTick(() => {
      this.$bvModal.show(ModelCopyModal.MODAL_ID);
    });
  }

  protected handleCopyModel(data: { name: string; createLink: boolean; openModel: boolean }): void {
    if (this.currentProject.id) {
      this.copyModel(this.selectedModel, this.currentProject.id, data)
        .then(() => {
          this.$bvModal.hide(ModelCopyModal.MODAL_ID);
          this.$emit('reload-project');
        })
        .catch((error) => {
          let modal = this.$refs.modelCopyModal as ModelCopyModal;
          switch (error.response.status) {
            default:
              modal.showNewNameValidationError(error.response.data.message);
              break;
          }
        });
    }
  }

  protected showExportModal(): void {
    this.$bvModal.hide('modal-show-model');
    Vue.nextTick(() => {
      (this.$refs.exportModal as ModelExportModal).show();
    });
  }

  protected showLinkDetails(): void {
    this.$bvModal.hide('modal-show-model');
    Vue.nextTick(() => {
      (this.$refs.modelLinksModal as ModelLinksModal).show();
    });
  }

  protected handleSubModalClose(): void {
    this.$bvModal.show('modal-show-model');
  }

  protected showAnalysisModal(): void {
    this.$bvModal.hide('modal-show-model');
    Vue.nextTick(() => {
      this.$bvModal.show('analysis-modal');
    });
  }

  protected showRunPipelinesModal(): void {
    this.$bvModal.hide('modal-show-model');
    Vue.nextTick(() => {
      this.$bvModal.show('run-pipelines-modal');
    });
  }

  protected showModelDetailReset(): void {
    this.selectedModelChangedName = this.selectedModel.name;
    this.selectedModelEditActive = false;
    this.selectedModelErrorMessage = 'Required (max. Length 255)';
    this.selectedModelValidatorState = null;
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
