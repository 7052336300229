var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.assignment)?_c('div',[(_vm.shouldBeVisible(_vm.PossibleAction.CAN_GET_ASSIGNMENT, _vm.project))?_c('b-modal',{attrs:{"centered":"","hide-footer":"","hide-header-close":"","id":"modal-show-assignment","title":"Review Assignment"}},[_c('b-form-group',{staticStyle:{"font-size":"larger"},attrs:{"label":"Name","label-cols-lg":"4","label-cols-sm":"4"}},[_c('b-form-input',{staticStyle:{"font-size":"large"},attrs:{"disabled":""},model:{value:(_vm.assignment.name),callback:function ($$v) {_vm.$set(_vm.assignment, "name", $$v)},expression:"assignment.name"}})],1),_c('b-form-group',{staticStyle:{"font-size":"larger"},attrs:{"label":"Current State","label-cols-lg":"4","label-cols-sm":"4"}},[_c('b-form-input',{staticStyle:{"font-size":"large"},attrs:{"disabled":""},model:{value:(_vm.assignment.state),callback:function ($$v) {_vm.$set(_vm.assignment, "state", $$v)},expression:"assignment.state"}})],1),_c('b-form-group',{staticStyle:{"font-size":"larger"},attrs:{"label":"Task Description","label-cols-lg":"4","label-cols-sm":"4"}},[_c('wysiwyg-editor',{attrs:{"editable":false},model:{value:(_vm.assignment.taskDescription),callback:function ($$v) {_vm.$set(_vm.assignment, "taskDescription", $$v)},expression:"assignment.taskDescription"}})],1),_c('b-form-group',{staticStyle:{"font-size":"larger"},attrs:{"label":"Creation Date","label-cols-lg":"4","label-cols-sm":"4"}},[_c('b-form-input',{staticStyle:{"font-size":"large"},attrs:{"value":_vm.format(_vm.assignment.created),"disabled":""}})],1),_c('div',{staticClass:"mt-4"},[(
          _vm.showReviewButton &&
          _vm.assignment &&
          _vm.loadReviewListOfAssignment() &&
          _vm.assignment.state === 'In Process' &&
          _vm.shouldBeVisible(_vm.PossibleAction.CAN_SAVE_REVIEW, _vm.project, _vm.assignment) &&
          this.currentReviewsOfUser.length === 0
        )?_c('b-button',{staticClass:"float-right ml-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.startReviewProcess(_vm.assignment)}}},[_vm._v(" Create Review ")]):_vm._e(),(
          _vm.showReviewButton &&
          _vm.assignment &&
          _vm.loadReviewListOfAssignment() &&
          _vm.assignment.state === 'In Process' &&
          _vm.shouldBeVisible(_vm.PossibleAction.CAN_SAVE_REVIEW, _vm.project, _vm.assignment) &&
          this.inProgressReviewsOfUser.length > 0
        )?_c('b-button',{staticClass:"float-right ml-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.resumeReviewProcess(_vm.inProgressReviewsOfUser[0], _vm.assignment)}}},[_vm._v(" Resume Review ")]):_vm._e(),(_vm.shouldBeVisible(_vm.PossibleAction.CAN_NAVIGATE_ASSIGNMENT_DETAILS))?_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","to":{
          name: 'assignmentDetails',
          params: { projectId: _vm.assignment.projectId, assignmentId: _vm.assignment.id },
        }}},[_vm._v(" Show Details ")]):_vm._e(),(
          _vm.shouldBeVisible(_vm.PossibleAction.CAN_DELETE_ASSIGNMENT, _vm.project, _vm.assignment) ||
          _vm.shouldBeVisible(_vm.PossibleAction.CAN_UPDATE_ASSIGNMENT, _vm.project, _vm.assignment)
        )?_c('b-dropdown',{staticClass:"float-left",attrs:{"variant":"primary","no-caret":"","dropup":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-list',{attrs:{"variant":"light"}})]},proxy:true}],null,false,3421779796)},[(_vm.shouldBeVisible(_vm.PossibleAction.CAN_UPDATE_ASSIGNMENT, _vm.project, _vm.assignment))?_c('b-dropdown-item',{attrs:{"disabled":_vm.assignment.approved},on:{"click":function($event){return _vm.showOverrideStateModel()}}},[_vm._v("Override State ")]):_vm._e(),(_vm.shouldBeVisible(_vm.PossibleAction.CAN_DELETE_ASSIGNMENT, _vm.project, _vm.assignment))?_c('b-dropdown-item',{attrs:{"disabled":!_vm.currentReviews || _vm.currentReviews.length > 0},on:{"click":function($event){return _vm.deleteReviewAssignment(_vm.assignment)}}},[_vm._v(" Delete Assignment ")]):_vm._e()],1):_vm._e()],1)],1):_vm._e(),_c('b-modal',{attrs:{"hide-header-close":"","ok-title":"Override","centered":"","id":"override-modal","size":"md","title":'Override State of Assignment: ' + _vm.assignment.name},on:{"ok":function($event){return _vm.overrideState($event, _vm.assignment)}}},[_c('b-form-group',{staticStyle:{"font-size":"large"},attrs:{"label":"New State *","label-for":"states","invalid-feedback":"Required"}},[_c('b-select',{attrs:{"id":"state","options":_vm.states,"state":_vm.stateSelectorState},model:{value:(_vm.selectedAssignmentState),callback:function ($$v) {_vm.selectedAssignmentState=$$v},expression:"selectedAssignmentState"}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }