import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class ProjectChanges {
  @JsonProperty('addOwners')
  private readonly _addedOwners: string[] = [];
  @JsonProperty('removeOwners')
  private readonly _removedOwners: string[] = [];
  @JsonProperty('addMembers')
  private readonly _addedMembers: string[] = [];
  @JsonProperty('removeMembers')
  private readonly _removedMembers: string[] = [];

  get addedOwners(): string[] {
    return this._addedOwners;
  }

  get removedOwners(): string[] {
    return this._removedOwners;
  }

  get addedMembers(): string[] {
    return this._addedMembers;
  }

  get removedMembers(): string[] {
    return this._removedMembers;
  }
}
