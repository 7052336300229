import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { ModelMixin } from '@/mixins/ModelMixin';
import Model from '@/models/Model';
import { deserialize, serialize } from 'typescript-json-serializer';
import ModelService from '@/services/ModelService';

@Component({})
export class CopyModelMixin extends mixins(Toasts, ModelMixin) {
  protected copyModel(
    model: Model,
    projectId: number,
    data: { name: string; createLink: boolean; openModel: boolean }
  ): Promise<void> {
    // clone model
    const newModel: Model = deserialize(JSON.parse(JSON.stringify(serialize(model))), Model);
    // copy
    if (data.createLink && model.id) {
      newModel.linkedModelIds.push(model.id);
    }

    // unset id to save as new model
    newModel.id = undefined;

    // set new name
    newModel.name = data.name;

    // save model
    return ModelService.save(newModel).then((savedModel) => {
      if (data.openModel) {
        // redirect to new model
        this.$router.push({
          name: 'modelEditor',
          params: { projectId: projectId + '', modelId: savedModel.id + '' },
        });
      } else {
        this.showToast('Copy created!', 'Copy of model successfully created.', 'success');
      }
    });
  }
}
