










































import { Component, Prop, Watch } from 'vue-property-decorator';
import ModelService from '@/services/ModelService';
import ExportFormats from '@/models/ExportFormats';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component
export default class ModelExportModal extends mixins(Toasts) {
  protected modelExportFileContent = '';
  protected modelExportModalSubmitText = 'Close';
  protected modelExportInProgress = false;
  protected modelExportErrorMessage = '';

  @Prop({
    required: true,
    default: '',
  })
  protected modelId!: string;

  @Prop({
    required: true,
    default: '',
  })
  protected modelName!: string;

  protected exportFormats: ExportFormats[] = [];

  protected selectedFormat: ExportFormats | null = null;

  created(): void {
    this.loadExportFormats();
  }

  get exportFormatsOptions(): { value: ExportFormats; text: string }[] {
    return this.exportFormats.map((format) => {
      return {
        value: format,
        text: format.displayName,
      };
    });
  }

  @Watch('selectedFormat')
  protected handleSelectedFormatChange(newVal: ExportFormats | null, oldVal: ExportFormats | null): void {
    if (this.modelId && newVal && newVal !== oldVal) {
      this.exportModal();
    }
  }

  public show(): void {
    this.exportModal();
    this.$bvModal.show('export-modal');
  }

  public hide(): void {
    this.$bvModal.hide('export-modal');
    this.$emit('hidden');
  }

  protected loadExportFormats(): void {
    ModelService.getExportFormats().then((formats) => {
      this.exportFormats = formats;
      if (this.exportFormats.map((exportFormat) => exportFormat.displayName).includes('JSON')) {
        this.selectedFormat = this.exportFormats[
          this.exportFormats.findIndex(function (exportFormat) {
            return exportFormat.displayName == 'JSON';
          })
        ];
      } else {
        this.selectedFormat = this.exportFormats[0];
      }
    });
  }

  protected exportModal(): void {
    if (this.selectedFormat) {
      this.modelExportInProgress = true;

      ModelService.export(this.modelId, this.selectedFormat.displayName)
        .then((exportModel) => {
          if (exportModel) {
            this.modelExportFileContent = exportModel;
          }
        })
        .catch((backendError) => {
          this.modelExportErrorMessage = 'Error: ' + backendError.response.data.message;
        })
        .finally(() => (this.modelExportInProgress = false));
    } else {
      this.modelExportErrorMessage = 'Please select a format';
    }
  }

  protected modelExportReset(): void {
    this.modelExportFileContent = '';
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  // https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
  protected exportAsFile(): void {
    if (this.selectedFormat) {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.modelExportFileContent));
      const fileName =
        moment(Date.now()).format('YYYY-MM-DD') +
        '-model-' +
        this.modelName
          .substring(0, Math.min(30, this.modelName.length))
          .replace(/[^a-z0-9]/gi, '-')
          .toLowerCase();
      element.setAttribute('download', fileName + '.' + this.selectedFormat.fileExtension);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
}
