

















































import { mixins } from 'vue-class-component';
import { FeatureMixin } from '@/mixins/FeatureMixin';
import { Getter, State } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { ModelDifferenceCompareListMixin } from '@/model-difference/mixins/ModelDifferenceCompareListMixin';
import Project from '@/models/Project';

@Component
export default class ModelCompareListOverlay extends mixins(FeatureMixin, ModelDifferenceCompareListMixin) {
  @Getter('compareList', {
    namespace: 'ModelDifference',
  })
  protected compareList!: string[];

  @State('currentProject', {
    namespace: 'project',
  })
  protected currentProject!: Project;

  get modelIds(): string[] {
    return Object.keys(this.compareList);
  }

  protected clearCompareList(): void {
    this.setCompareList({});
  }
}
