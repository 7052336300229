import Component, { mixins } from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import { hasPermission } from '@/auth/AuthService';
import ModelService from '@/services/ModelService';
import { PossibleAction } from '@/auth/PossibleAction';
import { Toasts } from '@/mixins/ToastMixins';
import Model from '@/models/Model';
import { LoadingMixin } from '@/mixins/CommonMixins';
import Project from '@/models/Project';

@Component({})
export class ProjectObject extends Vue {
  @State('currentProject', { namespace: 'project' })
  protected currentProject!: Project;
}

@Component({})
export class ProjectId extends Vue {
  @State('currentProjectId', { namespace: 'project' })
  protected currentProjectId!: number;
}

@Component({})
export class ProjectModels extends mixins(Toasts, ProjectObject, LoadingMixin) {
  @Action('setModels', { namespace: 'project' })
  protected setModels!: (models: Model[]) => void;

  @Mutation('addModel', { namespace: 'project' })
  protected addModel!: (model: Model) => void;

  @Mutation('removeModel', { namespace: 'project' })
  protected removeModel!: (model: Model) => void;

  @State('models', { namespace: 'project' })
  protected currentModels!: Model[];

  protected loadCurrentModels(projectId: number): void {
    if (hasPermission(PossibleAction.CAN_GET_MODEL, this.currentProject)) {
      ModelService.getByProjectId(projectId)
        .then((models) => {
          this.showToast('Successfully loaded', models.length + ' Models found.', 'info');
          this.setModels(models.reverse());
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load models.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }
}
