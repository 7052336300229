






























































































































































































































































































































































import ModelService from '@/services/ModelService';
import Model from '@/models/Model';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ProjectService from '@/services/ProjectService';
import { BDropdown } from 'bootstrap-vue';
import UserInfo from '@/models/users/UserInfo';
import { Action, Mutation, State } from 'vuex-class';
import ProjectChanges from '@/models/ProjectChanges';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import ModelImportModal from '@/components/modals/ModelImportModal.vue';
import AssignmentDetailsModal from '@/components/modals/AssignmentDetailsModal.vue';
import ModelDetailsModal from '@/components/modals/ModelDetailsModal.vue';
import ModelCreateModal from '@/components/modals/ModelCreateModal.vue';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import { Toasts } from '@/mixins/ToastMixins';
import { mixins } from 'vue-class-component';
import { FeatureMixin } from '@/mixins/FeatureMixin';
import ModelCompareButton from '@/model-difference/components/ModelCompareButton.vue';
import ModelCompareListOverlay from '@/model-difference/components/ModelCompareListOverlay.vue';
import { ProjectModels } from '@/mixins/ProjectMixins';

@Component({
  components: {
    ModelCreateModal,
    ModelDetailsModal,
    ModelImportModal,
    AssignmentDetailsModal,
    ModelCompareButton,
    ModelCompareListOverlay,
  },
})
export default class ProjectDetails extends mixins(Toasts, FeatureMixin, ProjectModels) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, project?: Project, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, project, assignment);
    return permission != null && permission;
  }

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @State('availableUsers', { namespace: 'userManagement' })
  protected availableUsers!: UserInfo[];

  @State('currentProject', { namespace: 'project' })
  protected currentProject!: Project;

  @Action('setCurrentProject', { namespace: 'project' })
  protected setCurrentProject!: (project: Project | undefined) => void;

  @Prop({ required: true, default: -1 })
  protected readonly projectId?: number;

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Projects', to: '/projects' },
    { text: 'Details', active: true },
  ];
  protected showSidebar = true;

  protected currentProjectOwners: UserInfo[] = [];
  protected currentProjectMembers: UserInfo[] = [];

  protected currentAssignments: ReviewAssignment[] = [];

  protected selectedAssignment: ReviewAssignment | null = null;

  protected selectedModel: Model | null = null;

  protected changeNameEnteredValue = '';
  protected changeNameValidatorState: boolean | null = null;

  protected changeOwnerActive = false;
  protected changeMemberActive = false;

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_GET_PROJECT)) {
      // reset models to empty state
      this.setModels([]);

      this.$on('finished-delete-project', () => {
        this.$router.replace({ path: '/projects' });
      });

      this.$on('finished-delete-assignment', (args) => {
        this.currentAssignments.splice(this.currentAssignments.indexOf(args.assignment), 1);
      });

      this.$on('finished-update-assignment', (args) => {
        if (this.selectedAssignment) {
          this.selectedAssignment.state = args.newState;
          this.selectedAssignment.approved = true;
        }
      });

      this.$on('finished-review-creation', (args) => {
        const review = args.review;
        this.$router.push({
          name: 'reviewProcess',
          params: { assignmentId: review.assignmentId, reviewId: review.id },
        });
      });

      this.loadCurrentProject();
    } else {
      this.$router.replace({ path: '/projects' }).finally(() => {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      });
    }
  }

  beforeDestroy(): void {
    this.setCurrentProject(undefined);
    this.$off('finished-delete-project');
    this.$off('finished-delete-assignment');
    this.$off('finished-update-assignment');
    this.$off('finished-review-creation');
  }

  protected loadCurrentProject(): void {
    if (hasPermission(PossibleAction.CAN_GET_PROJECT) && this.projectId) {
      this.setLoading(true);
      ProjectService.getById(this.projectId)
        .then((project) => {
          this.setCurrentProject(project);
          Vue.nextTick(() => {
            this.loadUserInfos(true, true);
            if (project.id) {
              if (hasPermission(PossibleAction.CAN_GET_MODEL, project)) {
                this.loadCurrentModels(project.id);
              }
              if (hasPermission(PossibleAction.CAN_GET_ASSIGNMENT, project)) {
                this.loadCurrentAssignments(project.id);
              }
            }
          });
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg,
            title = 'Failed to load';
          if (backendError.response.status === 404) {
            msg = 'Could not find the queried project.';
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router.replace({ path: '/projects' }).finally(() => {
            this.showToast(title, msg, 'danger');
          });
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected deleteCurrentProject(): void {
    if (hasPermission(PossibleAction.CAN_DELETE_PROJECT, this.currentProject)) {
      this.$root.$emit('delete-project', {
        project: this.currentProject,
        target: this,
      });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected updateProjectName(event: Event): void {
    if (hasPermission(PossibleAction.CAN_UPDATE_PROJECT, this.currentProject)) {
      event.preventDefault();
      if (this.changeNameEnteredValue !== this.currentProject.name && this.changeNameEnteredValue.length < 256) {
        this.setLoading(true);
        const oldName = this.currentProject.name;
        this.currentProject.name = this.changeNameEnteredValue;
        ProjectService.update(this.currentProject)
          .then((updatedProject) => {
            this.setCurrentProject(updatedProject);
            this.showToast(
              'Successfully updated',
              "Project '" + this.currentProject.name + "' successfully updated",
              'success'
            );
            (this.$refs.dropdown as BDropdown).hide();
          })
          .catch((backendError) => {
            this.currentProject.name = oldName;
            let msg,
              title = 'Failed to update';
            if (backendError.response.status === 409) {
              this.changeNameValidatorState = false;
              msg = this.changeNameEnteredValue + ' is already in use for a project.';
            } else if (backendError.response.status === 404) {
              msg = 'Could not find the project.';
            } else if (backendError.response.status === 403) {
              title = 'Action denied';
              msg = 'You do not have the required rights.';
            } else {
              msg = 'Could not update the project: ' + backendError.response.status;
            }
            this.showToast(title, msg, 'danger');
          })
          .finally(() => this.setLoading(false));
      } else {
        if (this.changeNameEnteredValue.length > 255) {
          this.showToast('Failed to update', 'Project is required (max. Length 255).', 'danger');
        }
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected updateProjectParticipants(owner: boolean): void {
    if (hasPermission(PossibleAction.CAN_UPDATE_PROJECT, this.currentProject)) {
      if (!owner || this.currentProjectOwners.length > 0) {
        const projectChanges = new ProjectChanges();
        let selectedUIDs: string[];
        let changesDetected = false;
        if (owner) {
          selectedUIDs = this.currentProjectOwners.map((user) => {
            return user.uid;
          });
          this.currentProject.owners.forEach((uid) => {
            if (selectedUIDs.indexOf(uid) === -1) {
              projectChanges.removedOwners.push(uid);
              changesDetected = true;
            }
          });
        } else {
          selectedUIDs = this.currentProjectMembers.map((user) => {
            return user.uid;
          });
          this.currentProject.members.forEach((uid) => {
            if (selectedUIDs.indexOf(uid) === -1) {
              projectChanges.removedMembers.push(uid);
              changesDetected = true;
            }
          });
        }
        selectedUIDs.forEach((uid) => {
          if (owner && this.currentProject.owners.indexOf(uid) === -1) {
            projectChanges.addedOwners.push(uid);
            changesDetected = true;
          }
          if (!owner && this.currentProject.members.indexOf(uid) === -1) {
            projectChanges.addedMembers.push(uid);
            changesDetected = true;
          }
        });
        if (changesDetected && this.currentProject && this.currentProject.id) {
          this.setLoading(true);
          ProjectService.updateParticipants(this.currentProject.id, projectChanges)
            .then(() => {
              if (owner) {
                this.currentProject.owners = selectedUIDs;
                this.changeOwnerActive = false;
              } else {
                this.currentProject.members = selectedUIDs;
                this.changeMemberActive = false;
              }
              this.currentProject.modified = new Date(Date.now());
              if (!hasPermission(PossibleAction.CAN_GET_PROJECT, this.currentProject)) {
                this.$router.replace({ path: '/projects' }).finally(() => {
                  this.showToast(
                    'Successfully updated',
                    "Project '" + this.currentProject.name + "' successfully updated",
                    'success'
                  );
                });
              } else {
                this.showToast(
                  'Successfully updated',
                  "Project '" + this.currentProject.name + "' successfully updated",
                  'success'
                );
              }
            })
            .catch((backendError) => {
              let msg,
                title = 'Failed to update';
              if (backendError.response.status === 404) {
                msg = 'Could not find the project.';
              } else if (backendError.response.status === 403) {
                title = 'Action denied';
                msg = 'You do not have the required rights.';
              } else {
                msg = 'Could not update the project: ' + backendError.response.status;
              }
              this.showToast(title, msg, 'danger');
            })
            .finally(() => this.setLoading(false));
        } else {
          this.showToast('No Changes Detected', 'There were not changes.', 'info');
          if (owner) {
            this.changeOwnerActive = false;
          } else {
            this.changeMemberActive = false;
          }
        }
      } else {
        this.showToast('Owner is missing', 'At least one owner must be set.', 'danger');
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadCurrentAssignments(projectId: number): void {
    if (hasPermission(PossibleAction.CAN_GET_ASSIGNMENT, this.currentProject)) {
      ReviewAssignmentService.getAllByProjectID(projectId)
        .then((assignments) => {
          this.showToast('Successfully loaded', assignments.length + ' Review Assignments found.', 'info');
          this.currentAssignments = assignments.reverse();
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load review assignments.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadUserInfos(owner: boolean, member: boolean): void {
    if (owner) {
      this.currentProjectOwners = [];
    }
    if (member) {
      this.currentProjectMembers = [];
    }
    this.availableUsers.forEach((value) => {
      if (owner && this.currentProject.owners.indexOf(value.uid) !== -1) {
        this.currentProjectOwners.push(value);
      }
      if (member && this.currentProject.members.indexOf(value.uid) !== -1) {
        this.currentProjectMembers.push(value);
      }
    });
  }

  protected getPossibleOwners(): UserInfo[] {
    return this.availableUsers.filter((user) => {
      const roles = user.roles.map((role) => UserRoleEnum[role]);

      let canOwnProject = roles.indexOf(UserRoleEnum.ROLE_ADMIN) !== -1;
      if (!canOwnProject) {
        canOwnProject = roles.indexOf(UserRoleEnum.ROLE_DESIGNER) !== -1;
      }
      return canOwnProject && this.currentProjectMembers.indexOf(user) === -1;
    });
  }

  protected getPossibleMembers(): UserInfo[] {
    return this.availableUsers.filter((user) => {
      return this.currentProjectOwners.indexOf(user) === -1;
    });
  }

  protected changeNameReset(): void {
    this.changeNameEnteredValue = this.currentProject.name;
    this.changeNameValidatorState = null;
  }

  protected changeParticipantsReset(owner: boolean): void {
    if (owner) {
      this.loadUserInfos(true, false);
      this.changeOwnerActive = false;
    } else {
      this.loadUserInfos(false, true);
      this.changeMemberActive = false;
    }
  }

  protected showModelCreation(): void {
    this.$bvModal.show('modal-create-model');
  }

  protected showModelDetails(model: Model): void {
    this.selectedModel = model;
    Vue.nextTick(() => {
      this.$bvModal.show('modal-show-model');
    });
  }

  protected showPipelineExecutions(model: Model): void {
    if (this.projectId != null && model.id != null) {
      this.$router.push({
        name: 'pipelineExecutions',
        params: { projectId: this.projectId.toString(), modelId: model.id },
      });
    }
  }

  protected createModel(args: { model: Model }): void {
    this.addModel(args.model);
  }

  protected deleteModel(args: { model: Model }): void {
    this.removeModel(args.model);
  }

  protected showAssignmentDetails(assignment: ReviewAssignment): void {
    this.selectedAssignment = assignment;
    Vue.nextTick(() => {
      this.$bvModal.show('modal-show-assignment');
    });
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected toggleSideBar(): void {
    this.showSidebar = !this.showSidebar;
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected showImportModal(): void {
    (this.$refs.importModal as ModelImportModal).show();
  }

  protected handleImportModalHidden(): void {
    if (this.projectId) {
      this.loadCurrentModels(this.projectId);
    }
  }

  protected openModelInEditor(model: Model): void {
    if (this.currentProject && this.currentProject.id && model && model.id) {
      this.$router.push({
        name: 'modelEditor',
        params: { projectId: this.currentProject.id.toString(), modelId: model.id },
      });
    }
  }
}
