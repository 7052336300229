



































































import { Component, Prop } from 'vue-property-decorator';
import Project from '@/models/Project';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { hasPermission } from '@/auth/AuthService';
import Model from '@/models/Model';
import ModelService from '@/services/ModelService';
import ModelConfigService from '@/services/ModelConfigService';
import ModelConfigId from '@/models/ModelConfigId';
import { PossibleAction } from '@/auth/PossibleAction';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component
export default class ModelCreateModal extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, project?: Project, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, project, assignment);
    return permission != null && permission;
  }

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @Prop({ required: true })
  protected currentProject!: Project;

  protected possibleConfigIDs: ModelConfigId[] | null = null;
  protected selectedConfigID: ModelConfigId | null = null;
  protected inProgress = false;
  protected enteredName: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  protected validators: any = {
    name: { state: null, errorMsg: 'Required (max. Length 255)' },
    config: { state: null, errorMsg: 'Required' },
  };

  protected loadPossibleConfigIDs(): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG_IDS)) {
      if (!this.possibleConfigIDs || this.possibleConfigIDs.length <= 0) {
        this.inProgress = true;
        ModelConfigService.getAllIds(true)
          .then((configOptions) => {
            this.possibleConfigIDs = configOptions;
          })
          .catch((backendError) => {
            if (backendError.response.status === 403) {
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
            } else {
              this.showToast('Failed to load', 'Could not load model configs.', 'danger');
            }
          })
          .finally(() => (this.inProgress = false));
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected createModel(event: Event): void {
    if (hasPermission(PossibleAction.CAN_SAVE_MODEL, this.currentProject)) {
      event.preventDefault();
      if (
        this.selectedConfigID &&
        this.enteredName &&
        this.enteredName.length > 0 &&
        this.enteredName.length < 256 &&
        this.currentProject.id
      ) {
        this.validators.name.state = true;
        this.validators.config.state = true;
        this.inProgress = true;
        const model = new Model(
          this.enteredName,
          this.selectedConfigID.id,
          this.selectedConfigID.modelType,
          this.selectedConfigID.version,
          this.currentProject.id
        );
        ModelService.save(model)
          .then((savedModel) => {
            this.$nextTick(() => {
              this.showToast('Successfully created', "'" + savedModel.name + "' successfully created.", 'success');
              this.$bvModal.hide('modal-create-model');
              this.$emit('create-model', { model: savedModel });
            });
          })
          .catch((backendError) => {
            if (backendError.response.status === 409) {
              this.validators.name.state = false;
              this.validators.name.errorMsg = 'Name is already in use for a model.';
            } else if (backendError.response.status === 400) {
              this.validators.config.state = false;
              this.validators.config.errorMsg = 'Model Config is inactive.';
            } else if (backendError.response.status === 403) {
              this.$bvModal.hide('modal-create-model');
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
            } else {
              this.showToast('Failed to create', 'Oops, something failed: ' + backendError.response.status, 'danger');
            }
          })
          .finally(() => (this.inProgress = false));
      } else {
        if (!this.selectedConfigID) {
          this.validators.config.state = false;
          this.validators.config.errorMsg = 'Required';
        } else {
          this.validators.config.state = null;
        }
        if (!this.enteredName || this.enteredName.length <= 0 || this.enteredName.length > 255) {
          this.validators.name.state = false;
          this.validators.name.errorMsg = 'Required (max. Length 255)';
        } else {
          this.validators.name.state = null;
        }
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected modelCreationReset(): void {
    this.enteredName = null;
    this.selectedConfigID = null;
    this.validators.name.state = null;
    this.validators.config.state = null;
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
