























import { Component, Prop, Watch } from 'vue-property-decorator';
import Project from '@/models/Project';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import moment from 'moment';
import Model from '@/models/Model';
import ModelService from '@/services/ModelService';
import ModelAnalysisResult from '@/models/ModelAnalysisResult';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component
export default class ModelAnalysisModal extends mixins(Toasts) {
  @Prop({ required: false })
  protected project?: Project;
  @Prop({ required: true })
  protected model!: Model;

  protected types: string[] = [];
  protected selectedAnalysisType: string | null = null;
  protected typeSelectorState: boolean | null = null;
  protected analysisResult: ModelAnalysisResult | null = null;
  protected disableOK = false;

  @Watch('selectedAnalysisType')
  protected typeChanged(): void {
    this.disableOK = false;
  }

  created(): void {
    this.loadAnalysisTypes();
  }

  protected loadAnalysisTypes(): void {
    ModelService.getAnalysisTypes().then((types) => {
      this.types = types;
    });
  }

  protected reset(): void {
    this.analysisResult = null;
    this.typeSelectorState = null;
    this.selectedAnalysisType = null;
  }

  protected analyseModel(event: Event, model: Model): void {
    event.preventDefault();
    if (this.selectedAnalysisType === null || this.selectedAnalysisType.length === 0) {
      this.typeSelectorState = false;
      return;
    }
    this.typeSelectorState = true;
    if (hasPermission(PossibleAction.CAN_ANALYSE_MODEL, this.project) && model.id) {
      ModelService.analyse(model.id, this.selectedAnalysisType)
        .then((result) => {
          this.disableOK = true;
          this.analysisResult = result;
        })
        .catch((backendError) => {
          let msg,
            title = 'Failed to analyse';
          if (backendError.response.status === 400) {
            msg = 'Analysis with the given name is not supported.';
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else if (backendError.response.status === 404) {
            msg = 'Could not find the queried model.';
          } else {
            msg = 'An unexpected error occurred while trying to analyse.';
          }
          this.showToast(title, msg, 'danger');
          this.$bvModal.hide('analysis-modal');
        });
    } else {
      this.$bvModal.hide('analysis-modal');
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }
}
