




















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ModelService from '@/services/ModelService';
import ModelConfigService from '@/services/ModelConfigService';
import ModelConfigId from '@/models/ModelConfigId';
import Model from '@/models/Model';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { ModalStatic } from '@/mixins/TestMixins';
import LinkedModelsHelper from '@/serializer/LinkedModelsHelper';
import ModelUpdate from '@/models/ModelUpdate';

@Component
export default class ModelImportModal extends mixins(Toasts, ModalStatic) {
  protected modelImportSelectedFile: File | null = null;
  protected modelImportValidatorState: boolean | null = null;
  protected modelImportFileContent = '';
  protected modelImportModalSubmitText = 'Import';
  protected modelImportInProgress = false;
  protected modelImportErrorMessage = '';
  protected modelConfigValidatorState: boolean | null = null;
  protected modelConfigErrorMessage = '';

  protected modelNameValidatorState: boolean | null = null;
  protected modelNameErrorMessage = '';

  protected importFormats: string[] = [];
  protected modelConfigIds: ModelConfigId[] = [];
  protected existingModels: Model[] = [];

  protected selectedFormat: string | null = null;
  protected selectedModelConfig: string | null = null;
  protected modelName = '';
  protected selectedModelIdToReplace: string | null = null;
  protected selectedModelIdsToLink: { [key: string]: string | null }[] = [];

  private readonly reader: FileReader = new FileReader();

  @Prop({
    required: true,
  })
  protected projectId!: number;

  created(): void {
    this.loadImportFormats();
    this.loadModelConfigs();
    this.loadReplaceableModels();
  }

  @Watch('modelImportSelectedFile')
  protected handleFileUploadChanged(newVal: File | null): void {
    if (newVal) {
      this.readSelectedFile();
      this.loadModelConfigs();
    }
  }

  public show(): void {
    this.$bvModal.show('import-modal');
    this.loadReplaceableModels();
  }

  public hide(): void {
    this.$bvModal.hide('import-modal');
    this.$emit('hidden');
  }

  protected modelImportSubmitted(modalEvent: Event): void {
    modalEvent.preventDefault();
    if (this.validate()) {
      this.modelImportValidatorState = true;
      this.importModel();
    }
  }

  protected validate(): boolean {
    let valid = true;

    if (!this.selectedModelConfig) {
      this.modelConfigErrorMessage = 'Please select a model config.';
      this.modelConfigValidatorState = false;
      valid = false;
    }
    if (this.modelName.trim().length === 0) {
      this.modelNameErrorMessage = 'Please enter a name.';
      this.modelNameValidatorState = false;
      valid = false;
    }
    if (valid) {
      this.modelConfigErrorMessage = '';
      this.modelConfigValidatorState = true;

      this.modelNameErrorMessage = '';
      this.modelNameValidatorState = true;
    }

    return valid;
  }

  protected loadImportFormats(): void {
    ModelService.getImportFormats().then((formats) => {
      this.importFormats = formats;
      if (this.importFormats.includes('JSON')) {
        this.selectedFormat = this.importFormats[this.importFormats.indexOf('JSON')];
      } else {
        this.selectedFormat = this.importFormats[0];
      }
    });
  }

  protected loadModelConfigs(): void {
    ModelConfigService.getAllIds()
      .then((modelConfigIds) => {
        this.modelConfigIds = modelConfigIds;
      })
      .catch(() => {
        this.showToast('Error', 'Failed loading model configs.', 'danger');
      });
  }

  protected loadReplaceableModels(): void {
    ModelService.getByProjectId(this.projectId)
      .then((models) => {
        this.existingModels = models;
      })
      .catch(() => {
        this.showToast('Error', 'Failed loading model configs.', 'danger');
      });
  }

  protected importModel(): void {
    if (this.modelImportFileContent.length > 0 && this.selectedFormat && this.selectedModelConfig) {
      this.modelImportInProgress = true;

      ModelService.import(
        this.projectId,
        this.selectedModelConfig,
        this.modelImportFileContent,
        this.modelName,
        this.selectedFormat,
        this.selectedModelIdToReplace
      )
        .then((savedModel) => {
          if (savedModel) {
            if (this.selectedModelIdsToLink.length > 0) {
              // set linked model ids
              savedModel.linkedModelIds = this.selectedModelIdsToLink.map((item) => item.value);
              // create ModelHistory Item
              const linkedModelHistoryEntry = LinkedModelsHelper.createLinkedModelSystemModelHistoryItem();
              ModelService.update(new ModelUpdate(savedModel, linkedModelHistoryEntry))
                .then(() => {
                  this.$nextTick(() => {
                    this.showToast(
                      'Successfully imported',
                      "Model '" + savedModel.name + "' successfully created.",
                      'success'
                    );
                    this.hide();
                  });
                })
                .catch((backendError) => {
                  console.log(backendError);
                  let msg,
                    title = 'Failed to update';
                  if (backendError.response.status === 404) {
                    msg = 'Could not find the model.';
                  } else if (backendError.response.status === 400) {
                    msg = backendError.response.data.message;
                  } else if (backendError.response.status === 403) {
                    title = 'Action denied';
                    msg = 'You do not have the required rights.';
                  } else {
                    msg = 'Could not update model: ' + backendError.response.status;
                  }
                  this.showToast(title, msg, 'danger');
                });
            } else {
              this.$nextTick(() => {
                this.showToast(
                  'Successfully imported',
                  "Model '" + savedModel.name + "' successfully created.",
                  'success'
                );
                this.hide();
              });
            }
          }
        })
        .catch((backendError) => {
          console.log(backendError);
          if (backendError.response.status === 409) {
            this.modelNameValidatorState = false;
            this.modelNameErrorMessage = 'Conflict with existing model. Error: ' + backendError.response.data.message;
          } else {
            this.modelImportErrorMessage = 'Model has an invalid form. Error: ' + backendError.response.data.message;
            this.modelImportValidatorState = false;
          }
          this.modelImportModalSubmitText = 'Import';
        })
        .finally(() => (this.modelImportInProgress = false));
    } else {
      this.modelImportValidatorState = false;
      this.modelImportErrorMessage = 'Input required';
    }
  }

  protected readSelectedFile(): void {
    if (this.modelImportSelectedFile) {
      this.reader.onloadstart = () => {
        this.modelImportInProgress = true;
      };
      this.reader.onload = () => {
        if (this.reader.result) {
          this.modelImportFileContent = this.reader.result.toString();
        }
        this.modelImportValidatorState = true;
        this.modelImportModalSubmitText = 'Upload';
      };
      this.reader.onerror = () => {
        this.modelImportValidatorState = false;
        this.showToast('Failed to read', 'Could not read the file.', 'danger');
      };
      this.reader.onloadend = () => {
        this.modelImportInProgress = false;
      };
      this.reader.readAsText(this.modelImportSelectedFile);
    } else {
      this.modelImportValidatorState = false;
      this.modelImportErrorMessage = 'Required';
    }
  }

  protected modelImportReset(): void {
    this.modelImportFileContent = '';
    this.modelImportSelectedFile = null;
    this.modelImportValidatorState = null;
    this.modelConfigValidatorState = null;
    this.modelConfigErrorMessage = '';
    this.modelName = '';
    this.modelNameErrorMessage = '';
    this.modelNameValidatorState = null;
    this.modelImportModalSubmitText = 'Upload';
    this.selectedModelConfig = null;
    this.selectedModelIdToReplace = null;
    this.selectedModelIdsToLink = [];
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  get modelConfigOptions(): { [key: string]: string | null }[] {
    const placeholder: { [key: string]: string | null } = {
      value: null,
      text: 'Choose model config...',
    };

    return [placeholder].concat(
      this.modelConfigIds.map((modelConfigId) => {
        return {
          value: modelConfigId.id,
          text: modelConfigId.modelType + ' ' + modelConfigId.version,
        };
      })
    );
  }

  get replaceableModelOptions(): { [key: string]: string | null }[] {
    const placeholder: { [key: string]: string | null } = {
      value: null,
      text: '(optional) Choose model to replace...',
    };

    return [placeholder].concat(
      this.existingModels.map((replaceableModel) => {
        return {
          value: replaceableModel.id ?? null,
          text: replaceableModel.name,
        };
      })
    );
  }

  get linkableModelOptions(): { [key: string]: string | null }[] {
    return this.existingModels.map((linkableModel) => {
      return {
        value: linkableModel.id ?? null,
        text: linkableModel.name,
      };
    });
  }
}
