




































import { mixins } from 'vue-class-component';
import { FeatureMixin } from '@/mixins/FeatureMixin';
import { Component, Prop } from 'vue-property-decorator';
import Model from '@/models/Model';
import { ModelDifferenceCompareListMixin } from '@/model-difference/mixins/ModelDifferenceCompareListMixin';
import { ProjectObject } from '@/mixins/ProjectMixins';

@Component({})
export default class ModelCompareButton extends mixins(FeatureMixin, ModelDifferenceCompareListMixin, ProjectObject) {
  @Prop({
    required: true,
  })
  protected model!: Model;

  get isButtonDisabled(): boolean {
    return (
      !this.modelCompareListHasCapacityLeft ||
      (this.modelDifferenceAllowedModelConfigId !== null &&
        this.modelDifferenceAllowedModelConfigId !== this.model.modelConfigId)
    );
  }
}
