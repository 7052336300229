























import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Model from '@/models/Model';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import ModelSummary from '@/models/ModelSummary';
import { Getter } from 'vuex-class';

@Component
export default class ModelLinksModal extends mixins(Toasts) {
  @Prop({
    required: true,
    default: () => null,
  })
  protected model!: Model | null;

  @Prop({
    required: true,
  })
  protected projectId!: string;

  @Getter('modelSummaries', { namespace: 'project' })
  protected modelSummaries!: { [key: string]: ModelSummary };

  get title(): string {
    return 'Linked models for: ' + (this.model?.name ?? '');
  }

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected getModelName(modelId: string): string {
    return this.modelSummaries[modelId]?.name ?? modelId;
  }
}
